import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function GodrejReadMore() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Godrej Developers</h4>
            
            <p className="mb-0">
                {/* <h6>VISION THAT DRIVES US FORWARD</h6> */}
                <p className="AboutExpoPara">Godrej Properties brings the Godrej Group philosophy of innovation, sustainability, and excellence to the real estate industry. Each Godrej Properties development combines a 125–year legacy of excellence and trusts with a commitment to cutting-edge design and technology.</p>

                {/* <h6>MISSION THAT WE’VE TAKEN OVER</h6> */}
                <p className="AboutExpoPara">In recent years, Godrej Properties has received over 250 awards and recognitions, including ‘The Most Trusted Real Estate Brand’ in 2019 from the Brand Trust Report, 'Real Estate Company of the Year' at the 9th Construction Week Awards 2019, ‘Equality and Diversity Champion’ 2019 at the APREA Property Leaders Awards, ‘The Economic Times Best Real Estate Brand 2018’ and the ‘Builder of the Year’ at the CNBC-Awaaz Real Estate Awards 2018.</p>
            </p>

            {isShowMore && (
                <p className="mb-0">
                    {/* <h6>Values</h6> */}
                    <p className="AboutExpoPara">Our projects over the years have delivered many firsts in the Indian real estate market. Planet Godrej, a skyscraper in Mumbai, was India's tallest occupied building when completed in 2008. It also illustrated our focus on customer safety and well-being by becoming the first project in the country to offer residents a fire escape chute. Our commercial office project, Godrej BKC, is the only LEED (Leadership in Energy and Environmental Design) Platinum-rated building in India's leading commercial district, Bandra Kurla Complex, demonstrating Godrej Properties' commitment to environmental sustainability. It is also the project where we broke the record for India's highest-ever commercial end-user sales transaction when a large multinational pharmaceutical company purchased space in this project for INR 1,479 crore in 2015. Our flagship project, The Trees, is one of India's most sustainably planned mixed-use projects that we hope will contribute to the evolution of urban design thinking in the country. We sold over INR 1,200 crore worth of space within six months of launching this project in 2015, making it one of the country's most successful residential project launches.</p>
                    {/* <p className="AboutExpoPara">Whenever we do work, we do it with the utmost sincerity. We keep everything transparent and always stand by the word we’ve given to our stakeholders.</p>
                    <p className="AboutExpoPara">We’re always driven by the fundamental human feeling in our hearts. We listen to and strive to understand our customers as well the needs of the society.</p>
                    <p className="AboutExpoPara">We create innovative solutions using our forward-thinking mindset. We always search out of the box to find the kind of perfection that shows vision.</p>

                    <h6>A JOURNEY TOWARDS IMMORTALITY</h6> */}
                    <p className="AboutExpoPara">With an estimated 10 million Indians moving into urban areas annually, the country's urban landscape is likely to change dramatically in the coming decades. We firmly believe that India must seize on the opportunity to urbanize in a sustainable manner. Our group has always been at the forefront of the environmental sustainability movement. The CII-Godrej Green Building Center in Hyderabad, when it was completed in 2004, was the first LEED Platinum building outside of the United States and was the single highest-rated LEED building in the world.  In 2010, Godrej Properties committed that every single project we develop will be a certified green building. Many of our projects have since received LEED Platinum certifications, which are globally recognized as the leading sustainability recognitions. Our large township project, Godrej Garden City, in Ahmedabad was selected as one of only 2 projects in India and 16 worldwide by The Clinton Foundation to partner with them in the goal of achieving a climate-positive development. In 2016, we stood 2nd in Asia and 5th in the world in the GRESB (Global Real Estate Sustainability Benchmarking) study, which is an industry-led sustainability and governance benchmarking platform. 
</p>

<p className="AboutExpoPara">In 2010, Godrej Properties became a publicly listed company through a successful IPO in which it raised USD 100 million. Godrej Properties also created a fund management subsidiary in 2016; Godrej Fund Management raised USD 275 million in the year's largest residential real estate focused fundraise in the country. We are one of India's only national developers with a strong presence across the country's leading real estate markets. In the financial year 2016, for the first time, Godrej Properties was India's largest publicly listed real estate developer by sales value having sold over INR 5,000 crore of real estate that year.  In the same year, we also delivered 0.56 million square meters (6 million square feet) of real estate in seven cities across India.

</p>

<p className="AboutExpoPara">We believe that it’s the people working at Godrej Properties who allow us to build an outstanding company that is bubbling with talent, dynamism, and inspiration. In recognition of our people and practices, Godrej Properties has been ranked as the number one real estate developer and amongst the top fifty companies overall four consecutive times by the Great Places to Work Institute in partnership with the Economic Times.


</p>



                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default GodrejReadMore;